import styled from '@emotion/styled/macro';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Fade,
  Grid,
  Slide,
  Typography,
  useTheme
} from '@mui/material';
import CardBackdrop from '../../assets/images/backgrounds/penumbra_backdrop.png';
import CustomerSatisfactionWhite from '../../assets/images/graphical/icons/customer-satisfaction-white.svg';
import CustomerSatisfaction from '../../assets/images/graphical/icons/customer-satisfaction.svg';
import KnowledgeWhite from '../../assets/images/graphical/icons/knowledge-white.svg';
import Knowledge from '../../assets/images/graphical/icons/knowledge.svg';
import SpaceTravelWhite from '../../assets/images/graphical/icons/space-travel-white.svg';
import SpaceTravel from '../../assets/images/graphical/icons/space-travel.svg';
import GiflarCard from '../../assets/images/gifflar/giflar_cardd.png';
import StetindWinding from '../../assets/images/rocket-projects/2021-stetind/Stetind-winding.png';
import StetindCarried from '../../assets/images/rocket-projects/2021-stetind/Stetind_euroc_2021.jpg';
import DropTest from '../../assets/images/rocket-projects/2022-birkeland/Drop_test.jpg';
import SACAssembly from '../../assets/images/rocket-projects/2022-birkeland/SAC_assembly.jpg';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header.jsx';
import AboutHistory from './AboutHistory.jsx';
import UNSectionTest from './UNSectionTest.jsx';

const FlipCardInner = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

const FlipCard = styled(Box)`
  background-color: transparent;
  perspective: 1000px;
  &:hover ${FlipCardInner} {
    transform: rotateY(180deg);
  }
`;

const FlipCardFront = styled(Card)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-image: url(${FlipCardInner});
`;

const FlipCardBack = styled(Card)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

const PropulseCard = styled(Card)`
  border-radius: 20px;
  box-shadow: none;
  overflow: hidden;
`;

const About = () => {
  const theme = useTheme();
  return (
    <>
      <Header
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1000
        }}
      />
      <Fade in>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 100,
              width: '100%',
              height: 120,
              background: (theme) =>
                `linear-gradient(0deg, ${theme.palette.background.default}00 0%, ${theme.palette.background.default}  50%)`
            }}
          />
          <Box
            sx={{
              position: 'relative',
              height: '100vh',
              width: '100%',
              background: `url(${StetindCarried})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backdropFilter: 'grayscale(50%)'
              }}
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                bgcolor: 'primary.main',
                opacity: '0.6',
                mixBlendMode: 'multiply'
              }}
            />
            <Container maxWidth="lg">
              <Slide direction="up" in timeout={1500}>
                <Box
                  sx={{ position: 'absolute', bottom: 0, p: { xs: 6, md: 10 } }}
                >
                  <Fade in timeout={5000}>
                    <Box>
                      <Typography
                        variant="h4"
                        color="primary.contrastText"
                        fontWeight={700}
                        fontSize={{ xs: 24, sm: 40, md: 60 }}
                        mb={2}
                        textAlign="left"
                      >
                        THE FASTEST
                        <br /> AND MOST ENERGETIC <br /> STUDENT PROJECT IN
                        NORWAY
                      </Typography>
                      <Typography
                        color="primary.contrastText"
                        variant="body1"
                        fontWeight={300}
                        sx={{ maxWidth: { xs: '100%', md: '50%' } }}
                      >
                        We are a student rocketry team at NTNU that gives
                        students hands-on experience with engineering projects.
                        Members get opportunities that they would not have had
                        as a regular student, going beyond theory and gaining
                        experiences that strengthens them as engineers. The
                        final product reaches thousands of kilometers an hour in
                        a matter of seconds, reaching higher than the tallest
                        mountains, far into the sky - all built by students in
                        their spare time!
                      </Typography>
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            </Container>
          </Box>

          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.paper',
              py: { xs: 6, sm: 6, md: 16 }
            }}
          >
            <Typography
              variant="h3"
              color="primary.main"
              fontWeight={500}
              fontSize={{ xs: 30, sm: 40, md: 50 }}
              mb={2}
              textAlign="center"
            >
              Organizational goals
            </Typography>
            <Grid container maxWidth="lg" margin="auto" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
              >
                <PropulseCard
                  sx={{
                    height: { xs: 'fit-content', sm: '320px', md: '310px' },
                    width: { xs: '300px', md: '240px' },
                    maxWidth: { xs: '90%', md: 'none' },
                    backgroundColor: 'background.default',
                    backgroundImage: 'none',
                    transition: 'transform 0.15s ease-in-out',
                    '&:hover': { transform: 'scale3d(1.02, 1.02, 1)' }
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <CardMedia
                      component="img"
                      image={SpaceTravelWhite}
                      alt="Space travel icon"
                      sx={{ width: '60%', margin: 'auto', mt: 4 }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      image={SpaceTravel}
                      alt="Space travel icon"
                      sx={{ width: '60%', margin: 'auto', mt: 4 }}
                    />
                  )}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="body1"
                      textAlign="center"
                      component="div"
                    >
                      <b>Reach space</b> with a self-developed liquid-fueled
                      rocket
                    </Typography>
                  </CardContent>
                </PropulseCard>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
              >
                <PropulseCard
                  sx={{
                    height: { xs: 'fit-content', sm: '320px', md: '310px' },
                    width: { xs: '300px', md: '240px' },
                    maxWidth: { xs: '90%', md: 'none' },
                    backgroundColor: 'background.default',
                    backgroundImage: 'none',
                    transition: 'transform 0.15s ease-in-out',
                    '&:hover': { transform: 'scale3d(1.02, 1.02, 1)' }
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <CardMedia
                      component="img"
                      image={CustomerSatisfactionWhite}
                      alt="Customer satisfaction icon"
                      sx={{ width: '60%', margin: 'auto', mt: 4 }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      image={CustomerSatisfaction}
                      alt="Customer satisfaction icon"
                      sx={{ width: '60%', margin: 'auto', mt: 4 }}
                    />
                  )}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="body1"
                      textAlign="center"
                      component="div"
                    >
                      <b>Allow students to develop</b> both socially and
                      technically in an open and professional arena
                    </Typography>
                  </CardContent>
                </PropulseCard>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
              >
                <PropulseCard
                  sx={{
                    height: { xs: 'fit-content', sm: '310px', md: '310px' },
                    width: { xs: '300px', md: '240px' },
                    maxWidth: { xs: '90%', md: 'none' },
                    backgroundColor: 'background.default',
                    backgroundImage: 'none',
                    transition: 'transform 0.15s ease-in-out',
                    '&:hover': { transform: 'scale3d(1.02, 1.02, 1)' }
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <CardMedia
                      component="img"
                      image={KnowledgeWhite}
                      alt="Knowledge icon"
                      sx={{ width: '60%', margin: 'auto', mt: 4 }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      image={Knowledge}
                      alt="Knowledge icon"
                      sx={{ width: '60%', margin: 'auto', mt: 4 }}
                    />
                  )}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="body1"
                      textAlign="center"
                      component="div"
                    >
                      <b>Increase space travel interest</b> and expertise in
                      Norway
                    </Typography>
                  </CardContent>
                </PropulseCard>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography
              pt={8}
              variant="h3"
              color="primary.main"
              fontWeight={500}
              textAlign="center"
            >
              Our history
            </Typography>
            <AboutHistory />
          </Box>
          <Box
            sx={{ backgroundColor: 'background.paper', width: '100%', py: 6 }}
          >
            <Container maxWidth="md">
              <Typography
                variant="h3"
                color="primary.main"
                fontWeight={500}
                mb={2}
                textAlign="center"
              >
                How we work
              </Typography>
              <Typography variant="body1" fontWeight={300} textAlign="left">
                In Propulse NTNU, the members are not only the researchers,
                designers, producers, and testers, but also the end-users -
                giving them firsthand experiences with every step of an
                engineering project.
              </Typography>
            </Container>
            <Container>
              <Grid container spacing={2} sx={{ mt: 4 }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
                >
                  <FlipCard width="260px" height="360px">
                    <FlipCardInner>
                      <FlipCardFront>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                            backgroundImage: `url(${GiflarCard})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={700}
                            fontSize="1.5em"
                            color="inhereit"
                            textAlign="left"
                            sx={{
                              p: 2,
                              pt: 12,
                              color: theme.palette.primary.contrastText,
                              background: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, rgba(0,0,0,0) 100%)`
                            }}
                          >
                            Design and Development
                          </Typography>
                        </Box>
                      </FlipCardFront>
                      <FlipCardBack>
                        <Box
                          sx={{
                            height: '100%',
                            backgroundImage: `url(${CardBackdrop})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            textAlign="left"
                            sx={{
                              fontWeight: 600,
                              height: '100%',
                              width: '100%',
                              p: 3,
                              display: 'flex',
                              alignItems: 'center',
                              backdropFilter: 'blur(1px)'
                            }}
                          >
                            We develop the whole rocket ourselves; from the
                            electronics and onboard software, to the airframe
                            and inner structure, to the engine. Fundamental
                            ideas are developed in the concept period, and
                            translated over to the design phase, where main
                            ideas turn into detailed 3D models, circuit designs,
                            software architecture, and the groundwork of
                            analysis.
                          </Typography>
                        </Box>
                      </FlipCardBack>
                    </FlipCardInner>
                  </FlipCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
                >
                  <FlipCard width="260px" height="360px">
                    <FlipCardInner>
                      <FlipCardFront>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                            backgroundImage: `url(${StetindWinding})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={700}
                            fontSize="1.5em"
                            color="inhereit"
                            textAlign="left"
                            sx={{
                              p: 2,
                              pt: 12,
                              color: theme.palette.primary.contrastText,
                              background: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, rgba(0,0,0,0) 100%)`
                            }}
                          >
                            Production
                          </Typography>
                        </Box>
                      </FlipCardFront>
                      <FlipCardBack>
                        <Box
                          sx={{
                            height: '100%',
                            backgroundImage: `url(${CardBackdrop})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            textAlign="left"
                            sx={{
                              fontWeight: 600,
                              height: '100%',
                              width: '100%',
                              p: 3,
                              display: 'flex',
                              alignItems: 'center',
                              backdropFilter: 'blur(1px)'
                            }}
                          >
                            To design a rocket is a huge task in itself, but to
                            design a rocket that can be manufactured with the
                            tools available is arguably a greater task. The
                            members are responsible for the production, engaging
                            with workshops and suppliers. Often they will
                            produce the parts themselves from scratch, giving
                            them a hands-on feel on how their designs translate
                            to production.
                          </Typography>
                        </Box>
                      </FlipCardBack>
                    </FlipCardInner>
                  </FlipCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
                >
                  <FlipCard width="260px" height="360px">
                    <FlipCardInner>
                      <FlipCardFront>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                            backgroundImage: `url(${DropTest})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={700}
                            fontSize="1.5em"
                            color="inhereit"
                            textAlign="left"
                            sx={{
                              p: 2,
                              pt: 12,
                              color: theme.palette.primary.contrastText,
                              background: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, rgba(0,0,0,0) 100%)`
                            }}
                          >
                            Testing
                          </Typography>
                        </Box>
                      </FlipCardFront>
                      <FlipCardBack>
                        <Box
                          sx={{
                            height: '100%',
                            backgroundImage: `url(${CardBackdrop})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            textAlign="left"
                            sx={{
                              fontWeight: 600,
                              height: '100%',
                              width: '100%',
                              p: 3,
                              display: 'flex',
                              alignItems: 'center',
                              backdropFilter: 'blur(1px)'
                            }}
                          >
                            Testing and verification is an a vital part of
                            developing our rockets. The members are responsible
                            for planning and executing the tests, and then to
                            verify and document the results and processes.
                            Typical tests are small-scale rocket launches to
                            verify the avionics system, dropping a test article
                            from a helicopter to verify the parachute
                            deployment, and engine cold- and hotfires.
                          </Typography>
                        </Box>
                      </FlipCardBack>
                    </FlipCardInner>
                  </FlipCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
                >
                  <FlipCard width="260px" height="360px">
                    <FlipCardInner>
                      <FlipCardFront>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                            backgroundImage: `url(${SACAssembly})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={700}
                            fontSize="1.5em"
                            color="inhereit"
                            textAlign="left"
                            sx={{
                              p: 2,
                              pt: 12,
                              color: theme.palette.primary.contrastText,
                              background: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, rgba(0,0,0,0) 100%)`
                            }}
                          >
                            Launch Operation
                          </Typography>
                        </Box>
                      </FlipCardFront>
                      <FlipCardBack>
                        <Box
                          sx={{
                            height: '100%',
                            backgroundImage: `url(${CardBackdrop})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            textAlign="left"
                            sx={{
                              fontWeight: 600,
                              height: '100%',
                              width: '100%',
                              p: 3,
                              display: 'flex',
                              alignItems: 'center',
                              backdropFilter: 'blur(1px)'
                            }}
                          >
                            For the launch campaign, the members are assigned
                            additional roles; The assembly team prepares the
                            rocket for launch, while the launch crew is
                            responsible for bringing it to the launch rail and
                            arming the rocket. The ground station team monitors
                            the flight using a live data-feed, and when the
                            rocket has landed, a recovery crew is responsible
                            for retrieving it.
                          </Typography>
                        </Box>
                      </FlipCardBack>
                    </FlipCardInner>
                  </FlipCard>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <UNSectionTest></UNSectionTest>
          <Footer />
        </Box>
      </Fade>
    </>
  );
};

export default About;
