import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import UN4 from '../../assets/images/graphical/icons/un-sustainability/UN4.png';
import UN5 from '../../assets/images/graphical/icons/un-sustainability/UN5.png';
import UN9 from '../../assets/images/graphical/icons/un-sustainability/UN9.png';

const PropulseCard = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  boxShadow: 'none',
  overflow: 'hidden',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.background.default,
  maxWidth: '90%',
  transition: 'transform 0.15s ease-in-out',
  '&:hover': {
    transform: 'scale3d(1.02, 1.02, 1)'
  },
  border: `2px solid ${theme.palette.divider}`
}));

function UNSectionTest() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'background.paper',
        py: { xs: 6, sm: 6, md: 16 }
      }}
    >
      <Typography
        variant="h3"
        color="primary.main"
        fontWeight={500}
        fontSize={{ xs: 30, sm: 40, md: 50 }}
        mb={2}
        textAlign="center"
      >
        Accelerating Innovation and Sustainability
      </Typography>
      <Grid
        container
        maxWidth="lg"
        margin="auto"
        justifyContent="center"
        spacing={4}
      >
        {[
          {
            image: UN4,
            alt: 'quality education',
            text: 'At the heart of our mission is the belief that <b>hands on experience</b> drives real progress and learning.'
          },
          {
            image: UN5,
            alt: 'gender equality',
            text: 'Our rocket program promotes <b>equal opportunities</b>, empowering people of all genders to learn, lead, and launch.'
          },
          {
            image: UN9,
            alt: 'industry, innovation and infrastructure',
            text: 'Even as students, we <b>design most of our components in-house</b>, driving innovation and pushing the boundaries of aerospace technology.'
          }
        ].map((card, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center', my: 4 }}
          >
            <PropulseCard>
              <CardMedia
                component="img"
                image={card.image}
                alt={`UN sustainability goal: ${card.alt}`}
                sx={{
                  width: '60%',
                  margin: 'auto',
                  mt: 6,
                  borderRadius: '3%'
                }}
              />
              <CardContent>
                <Typography
                  variant="body1"
                  sx={{ width: '65%', margin: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: card.text }}
                />
              </CardContent>
            </PropulseCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
export default UNSectionTest;
