import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const VideoComponent = ({ videoSrc }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Ensuring the video is muted for iOS autoplay
      videoRef.current.setAttribute('muted', '');
      videoRef.current.muted = true;
    }
  }, []);

  return (
    <motion.video
      ref={videoRef}
      src={videoSrc}
      autoPlay
      muted // Set the muted attribute for compatibility for normal people
      loop
      style={{
        width: '40%',
        height: '100%',
        objectFit: 'cover'
      }}
    />
  );
};

export default VideoComponent;
